import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import {
  Route
} from 'react-router-dom'

export default class CompletedWorkoutListComponent extends React.Component {
  constructor (props) {
    super(props)

    const slicedUrl = window.location.href.split('?q=')

    this.loadContent = this.loadContent.bind(this)
    if (slicedUrl.length > 1) {
      const query = decodeURI(slicedUrl[1])
      this.state = {
        searchText: query
      }
      this.performSearch(query)
    } else {
      this.state = {
      }
      this.loadContent()
    }
  }

  loadContent = () => {
    const content = new Parse.Object('CompletedWorkout_v3')
    const query = new Parse.Query(content)
    query.descending('createdAt')
    query.include('workout')
    query.find().then((results) => {
      this.setState({
        completedWorkouts: results
      })
    }, (error) => {
      alert('Error getting content: ' + error.message)
    })
  }

  numberOfResultsText = () => {
    if (!this.state.members) return (<i>Loading...</i>)
    if (this.state.members.length === 100) return (<i>100+ results</i>)
    return (<i>{this.state.members.length} results</i>)
  }

  handleSearchChange = (event) => {
    this.setState({
      searchText: event.target.value
    })
  }

  handleSearch = (event) => {
    event.preventDefault()

    const query = this.state.searchText
    if (query && query.length > 0) {
      window.location.href = '/#/completedWorkouts?q=' + encodeURI(query)
    } else {
      window.location.href = '/#/completedWorkouts'
    }
    window.location.reload()
  }

  performSearch = (text) => {
    console.log('[CompletedWorkoutListComponent] Searching ' + text)

    const query = this.createSearchQuery(text)
    if (!query) {
      window.alert('Use keys for searching.')
      return
    }
    query.find().then((results) => {
      console.log('[CompletedWorkoutListComponent] Got ' + results.length + ' results.')
      const sorted = results.sort((lhs, rhs) => {
        if (lhs.get('createdAt') < rhs.get('createdAt')) {
          return 1
        } else if (lhs.get('createdAt') > rhs.get('createdAt')) {
          return -1
        }
        return 0
      })
      this.setState({
        completedWorkouts: sorted
      })
    }, (error) => {
      window.alert('Error searching. ' + error.message)
    })
  }

  createSearchQuery = (text) => {
    const searchDict = {}
    const words = text.split(' ')
    for (let i = 0; i < words.length; i++) {
      const word = words[i]
      const isTermValid = word.split(':').length > 1
      if (word.startsWith('userId:') && isTermValid) {
        searchDict.userId = word.split(':')[1]
      }
    }
    if (Object.keys(searchDict).length === 0) {
      return null
    }

    // currently supported token types:
    // - user email (User table)
    // - user objectId (User table)
    // - user first name (UserInfo table)
    // - user last name (UserInfo table)
    const completedWorkoutType = new Parse.Object('CompletedWorkout_v3')

    const query = new Parse.Query(completedWorkoutType)

    if (searchDict.userId) {
      console.log('[CompletedWorkoutListComponent] Query: userId: ' + searchDict.userId)
      const searchUser = new Parse.User()
      searchUser.id = searchDict.userId
      query.equalTo('user', searchUser)
    }
    console.log(query)
    return query
  }

  renderTableHeader = () => {
    return (
            <tr key={'0-header'} className="data">
                <th className="bg-primary" key={'0h'}>Workout</th>
                <th className="bg-primary" key={'1h'}>Created At ▾</th>
                <th className="bg-primary" key={'2h'}>Source</th>
                <th className="bg-primary" key={'3h'}>Info</th>
            </tr>
    )
  }

  renderTable = () => {
    if (!this.state.completedWorkouts) return ''
    return this.state.completedWorkouts.map((completedWorkout, index) => {
      return (
        <tr key={index} className="data">
          <td>
            <a href={'/#/completedWorkouts/' + completedWorkout.id}>
              <b>
                { completedWorkout.get('title') }
              </b>
            </a>
            <br/>
            { completedWorkout.get('dateCompleted').toLocaleString() }
          </td>
          <td>
          { completedWorkout.get('createdAt').toLocaleString() }
          </td>
          <td>
            <a href={'/#/members/' + completedWorkout.get('user').id}>
              { 'Member ' + completedWorkout.get('user').id }
            </a>
            <br/>
            { completedWorkout.get('modelName') }
            <span className="text-muted">{ ' ' + (completedWorkout.get('appVersion') || '') }</span>
          </td>
          <td>
            { completedWorkout.get('heartRateData') && completedWorkout.get('heartRateData').length ? <span title="HR samples">❤️</span> : '' }
            { completedWorkout.get('lapSamplesData') && completedWorkout.get('lapSamplesData').length ? <span title="Lap samples">⏱️</span> : '' }
            { completedWorkout.get('locationSamples') && completedWorkout.get('locationSamples').length ? <span title="GPS samples">🗺</span> : '' }
            { completedWorkout.get('workout') && completedWorkout.get('workout').get('isTestSetWorkout') ? <span title="Test Set">🏁</span> : '' }
            { completedWorkout.get('isGenerated') ? <span title="Generated workout">🪄</span> : '' }
            { completedWorkout.get('garminConnectWorkoutId') && completedWorkout.get('garminConnectWorkoutId').length ? <span title="Garmin Connect workout">📲</span> : '' }
            <br/>
            <span className="text-muted">{ completedWorkout.get('isGuided') ? 'Guided' : '' }</span>
          </td>
        </tr>
      )
    })
  }

  render = () => {
    return (
        <div>
            <Route path="/completedWorkouts">
                <div className="container">
                    <br/>
                    <div>
                        <h1 id="title">Completed Activities</h1>
                    </div>
                    <br/>
                    <form>
                        <div className="form-row">
                            <div className="form-group col">
                                <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Search" onChange={this.handleSearchChange} value={this.state.searchText}/>
                                <small id="emailHelp" className="form-text text-muted">Available tags: <code>userId:abc123</code>. You can combine multiple tags.</small>
                            </div>
                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary" onClick={this.handleSearch}>Search</button>
                            </div>
                        </div>
                    </form>
                    <table id="featuredcontent" className="data">
                        <tbody>
                            {this.renderTableHeader()}
                            {this.renderTable()}
                        </tbody>
                    </table>
                    <br/>
                    { this.numberOfResultsText() }
                    <br/>
                </div>
            </Route>
        </div>)
  }
}
